import React from "react"
import { graphql } from "gatsby"
import { ProductPageContent } from "../components/product-page-content"

export const pageQuery = graphql`
  query AssistQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
      }
    }
  }
`
const Assistance = ({ data }) => {
  const {
    markdownRemark
  } = data
  const { frontmatter: { title }, html, excerpt } = markdownRemark

  return <ProductPageContent pageName="assistance" title={title} html={html} excerpt={excerpt}/>
}

export default Assistance
